.speech-text {
  span {
    opacity: 0;
    &.revealed {
      opacity: 1;
    }
  }

  .break {
    display: block;
    margin-bottom: 0.5em;
  }
}
