$dialogue-margin: 0.6em;
$response-pointer-size: 2em;

@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dialogue-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .balloon {
    display: none;
    position: absolute;
    left: $dialogue-margin;
    width: calc(100% - 2 * $dialogue-margin);
    min-height: calc(33% - 2 * $dialogue-margin);
    background-color: $c-white;
    color: $c-black;
    border-radius: 1em;
    border: 0.5em solid $c-black;
    margin: 0;
    padding: 0.6em 1em;
    font-size: 1.5em;

    .title {
      font-size: 0.85em;
      font-weight: bold;
      margin-top: -0.5em;
      margin-bottom: -0.1em;
    }

    &.top {
      top: $dialogue-margin;
    }

    &.bottom {
      bottom: $dialogue-margin;;
    }

    &.visible {
      display: block;
      animation: popIn 0.25s ease-in-out;
    }

    &.fading {
      animation: fadeOut 0.25s ease-in-out forwards;
    }
  }

  @keyframes strikeDown {
      0% {
        transform: translateY(0.25em);
      }

      45% {
        transform: translateY(-0.15em);
      }

      90% {
        transform: translateY(0.25em);
      }

      100% {
        transform: translateY(0.25em);
      }
  }

  .balloon-speech.press-to-continue {
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      bottom: -1.15em;
      left: 50%;
      width: 2em;
      height: 2em;
      margin-left: -1em;
      background-image: url('../../static/icons/arrow-triangle-down.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      animation: strikeDown 0.75s ease-in-out infinite;
    }
  }

  /* Animate the background color from white to yellow */
  @keyframes flash {
      0% {
      background-color: $c-white;
      }

      100% {
      background-color: #ffe34b;
      }
  }

  .response-option {
    padding-left: $response-pointer-size;
    &.selected {
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        width: $response-pointer-size;
        height: $response-pointer-size;
        margin-left: -1 * $response-pointer-size;
        margin-top: -0.1 * $response-pointer-size;
        background-image: url('../../static/openmoji/backhand-index-pointing-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .text {
        background-color: $c-white;
        animation: flash 0.5s ease-out forwards;
      }
    }

    .text {
      padding: 0 0.1em;
    }
  }
}
