.title-overlay {
  background-color: #2b2b2b;
  background-image: url('../../static/ui/intro.svg');
  background-size: contain;
  background-position: center center;

  .logo {
    display: none;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.intro-screen {
  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../../static/ui/keys.svg');
    background-size: 48%;
    background-repeat: no-repeat;
    background-position: center 86%;
    opacity: 0;
    animation: appear 1s 0.5s forwards;
  }
}
