@keyframes score {
  0% {
      opacity: 0;
      transform: scale(0.5)
  }
  30% {
      opacity: 1;
      transform: scale(1)
  }
  95% {
      opacity: 1;
      transform: scale(1)
  }
  100% {
      opacity: 0;
      transform: scale(1)
  }
}

.scoring-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0.85;

  .achievement {
    width: 40%;
    height: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: score 1.5s ease-out forwards;
  }

  .inclusion {
    width: 80%;
    height: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: score 2.5s ease-out forwards;
    font-size: 2.1em;
    text-align: center;
    margin-top: -4.5em;

    .inclusion-title, .inclusion-image-container {
      opacity: 0;
      animation: score 2.25s ease-out forwards 0.25s;
    }

    .inclusion-title {
      display: inline-block;
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase;
      line-height: 1;
      color: black;
      background-color: $c-lime-green;
      padding: 0.3em 0.75em;
      white-space: nowrap;
      margin-bottom: 0.6em;
    }

    .inclusion-image-container {
      display: block;
      max-width: 40%;
      height: 100%;
      background-color: $c-yellow;
      border-radius: 50%;
      margin: auto;
      padding: 1%;
    }

    .inclusion-image {
      width: 100%;
      height: 100%;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}
