@use "sass:map";
$logo-width: 50%;

.title-overlay {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.visible {
    opacity: 1;
  }

  .logo {
    display: block;
    position: absolute;
    width: $logo-width;
    height: $logo-width * 0.64;
    top: 10%;
    left: (100% - $logo-width) * 0.5;
    background-image: url('../../static/logo/logo-two-lines.svg');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    background-color: white;
  }

  /* Opacity pulse animation for the start text */
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .press-start {
    position: absolute;
    bottom: 13%;
    width: 100%;
    height: auto;
    text-align: center;

    .frame {
      display: inline-block;
      @include headingText;
      text-transform: uppercase;
      color: white;
      font-size: 2em;
      background-color: $c-black;
      padding: 0.2em 1em;
    }

    .text {
      display: inline;
      animation: pulse 1.5s infinite ease-in-out;
    }
  }
}

@for $i from 1 through 6 {
  .player-#{$i} .title-overlay {
    .logo {
      background-color: map.get($player-colors-light, $i);
    }
  }
}
