$margin: 0.8em;

.quest-overlay {
  position: absolute;
  left: $margin;
  bottom: $margin;
  width: calc(90% - 4 * $margin);
  border: 0.22em solid $c-black;
  background-color: #ffeb78;
  color: $c-black;
  margin: 0;
  padding: 0.6em 1em;
  font-size: 1.5em;
  @include RobotoCondensedBold;
  transform: translateY(calc(100% + $margin));
  transition: transform 0.3s ease-in-out 0s;

  &.visible {
    transform: translateY(0);
    transition-delay: 0.3s;
  }

  $counter-size: 1.5em;
  $counter-empty-margin: 0.2em;

  .counter {
    margin: 0 -0.2em;
    .counter-item {
      display: inline-block;
      width: $counter-size - $counter-empty-margin;
      height: $counter-size - $counter-empty-margin;
      border: 0.125em dashed $c-black;
      border-radius: 50%;
      margin: $counter-empty-margin * 0.5  0.1 + $counter-empty-margin * 0.5;

        @keyframes activate {
            0% {
              opacity: 0;
              transform: scale(2);
            }
            50% {
              opacity: 1;
            }
            100% {
              transform: scale(1);
            }
        }

      &.active {
        width: $counter-size;
        height: $counter-size;
        margin: 0 0.1em;
        border: none;
        border-radius: 0;
        animation: activate 0.5s ease-in-out;
        background-image: url('../../static/openmoji/counters/check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .counter-item.happy.active {
      background-image: url('../../static/openmoji/counters/happy.svg');
    }

    .counter-item.angry.active {
      background-image: url('../../static/openmoji/counters/frown.svg');
    }

    .counter-item.idea.active {
      background-image: url('../../static/openmoji/counters/lightbulb.svg');
    }

    .counter-item.person.active {
      background-image: url('../../static/openmoji/counters/person.svg');
    }
  }

  &.with-checkmark {
    padding-left: 2.4em;

    &:before {
      content: " ";
      display: block;
      width: 1.3em;
      height: 1.3em;
      position: absolute;
      left: 0.6em;
      top: 0.7em;
      background-image: url('../../static/ui/quest-checkbox-box.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @keyframes check {
    0% {
      transform: scale(2);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1);
    }
  }

  &.with-checkmark-checked {
    &:after {
      content: " ";
      display: block;
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      top: 0.35em;
      left: 0.7em;
      background-image: url('../../static/ui/quest-checkbox-check.svg');
      background-repeat: no-repeat;
      background-size: contain;
      animation: check 0.5s ease-in-out;
      animation-iteration-count: 1;
    }
  }
}
