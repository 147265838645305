@keyframes fallIn {
  0% {
    transform: translate(100%, -100%) rotate(58deg);
  }

  100% {
    transform: none;
  }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.decision-screen-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  .decision-screen {
    transform: translate(100%, -100%) rotate(58deg);
  }

  .decision-screen-icon {
    opacity: 0;
  }

  &.visible {
    .decision-screen {
      animation: fallIn 0.5s ease-out forwards;
    }

    .decision-screen-icon {
      animation: fadeIn 0.5s 1.5s ease-out forwards;
    }
  }
}

.decision-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffe34a;
  color: black;
  text-align: center;
  padding: 2.5em 5em;

  .decision-screen-title {
    font-size: 1.4em;
    color: black;
  }

  .decision-screen-text {
    font-size: 1.4em;
  }

  .decision-screen-icon {
    width: 100%;
    height: 10em;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .waiting-text {
    margin-top: 2em;
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
    padding: 0.25em 0.9em;
    .text {
      animation: pulse 1.5s infinite ease-in-out;
    }
  }

  .inclusion-bar-wrapper {
    position: absolute;
    left: 5em;
    right: 5em;
    bottom: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.decision-screen-wrapper {
  .citizensEmpowered .decision-screen-icon {
    background-image: url('../../static/openmoji/decisions/empowerment.svg');
    margin-top: 0.5em;
  }

  .citizensHappy .decision-screen-icon {
    background-image: url('../../static/openmoji/decisions/happy.svg');
  }

  .citizensNeutral .decision-screen-icon {
    background-image: url('../../static/openmoji/decisions/neutral.svg');
  }

  .citizensSad .decision-screen-icon {
    background-image: url('../../static/openmoji/decisions/sad.svg');
  }

  .citizensAngry .decision-screen-icon {
    background-image: url('../../static/openmoji/decisions/angry.svg');
  }
}
