.text-screen {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include regularText;
  font-weight: bold;
  font-size: 2em;
  background-color: $c-black;
  color: $c-white;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3em;

    // Center the text horizontally and vertically
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
