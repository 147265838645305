@mixin RobotoRegular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

@mixin RobotoBold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

@mixin RobotoCondensedRegular {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin RobotoCondensedBold {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin RobotoCondensedRegularItalic {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin RobotoCondensedBoldItalic {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-style: italic;
}
