.connection-state-view {
  display: none;
  pointer-events: none;
  position: absolute;
  width: 300px;
  padding-top: 135px;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  color: black;

  font-size: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;

  &.visible {
    display: block;
  }

  .connection-state-view-icon {
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 25px;
    left: 100px;
  }

  .connection-state-view-error {
    font-weight: bold;
  }

  .connection-state-view-status {
    font-size: 0.85em;
    margin-bottom: 1.2em;
  }
}
