html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  @include regularText;
  background-color: $c-black;
  color: $c-white;

  &.bg-black {
    background-color: #000;
  }
}

h1, h2, h3, h4, h5, h6 {
  @include headingText;
  color: $c-white;
}
