.intro-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $c-lime-green;
  color: $c-black;
  text-align: center;
  padding: 10em 5em 2.5em;

  .intro-screen-text {
    @include RobotoBold;
    font-size: 1.75em;
  }

  @keyframes halfpulse {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .waiting-text {
    margin-top: 2em;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.25em 0.9em;
    background-color: $c-black;
    display: inline-block;
    .text {
      display: inline-block;
      color: $c-lime-green;
      padding: 0 1.5em;
      animation: halfpulse 1.5s infinite ease-in-out;
    }
  }

  .inclusion-bar-wrapper {
    position: absolute;
    left: 5em;
    right: 5em;
    bottom: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
