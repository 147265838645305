.emoji {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
}

.emoji.emoji-love {
  background-image: url('../../static/openmoji/emojis/love.svg');
  margin-bottom: -0.1em;
}

.emoji.emoji-speech_bubble {
  background-image: url('../../static/emojis/speech-bubble.svg');
  margin-bottom: -0.2em;
  font-size: 1.2em;
}
